import React from 'react'
import Simulado from '../../components/Simulado'

const SimuladoPage = () => {
  return (
        <Simulado/>
  )
}

export default SimuladoPage
