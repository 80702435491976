import styled from "styled-components";
//import {paleta1} from "../../../entities/paleta"
 
export const Container = styled.div`
   
  
`

/*Paleta1
  AzulPrincipal: '#3498DB',
  AzulClaro: '#87CEEB',
  AzulEscuro: '#001F3F',
  VerdeÁgua: '#00CED1',
  AmareloPálido: '#FFFF99',
  CinzaClaro: '#D3D3D3',
  RosaPálido: '#FFD1DC',
  LaranjaSuave: '#FFA07A',
  MarromClaro: '#D2B48C',
  VerdeMusgo: '#ADFF2F',
*/